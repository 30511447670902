import { PageProps } from 'gatsby';
import { FC } from 'react';
import { ContentSection, Hero, Layout, References } from '~/components';
import { Frontmatter } from '~/config';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Library: References',
  orderInLib: 31,
  screenShoot: false,
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout>
      {/* hero: block for menu */}
      <Hero />
      <ContentSection>
        <References
          references={[
            'chan a',
            'martin m',
            'package insert',
            'barcenas ch',
            'perez ea',
            'recurrent bc',
            'sun l',
            'pierce jp',
            'cameron d',
            'von minckwitz',
            'helwick c',
            'gianni l',
            'lin nu',
            'lin nu amiri-kordestani',
            'leone jp',
            'untch m',
            'piccart m',
            'moy b',
            'puma biotechnology',
            'us department',
            'saura c',
            'referenced for bc',
            'referenced for cnsc',
          ]}
        />
      </ContentSection>
    </Layout>
  );
};

export default Page;
